@import url(https://fonts.googleapis.com/css2?family=Comfortaa:wght@300&display=swap);
body {
  font-family: 'Comfortaa', cursive;
}

main {
  min-height: 80vh;
}

.factory-img-container img {
    width: 75%;
    /*height: 480px;*/
    /*border-radius: 30px 0px 0px 30px;*/
    border-radius: 10px;
    padding-bottom: 10px;

  }
